import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    '@keyframes dropItLikeItsHot': {
      '0%': {
        top: -550,
      },
      '50%': {
        top: -55,
      },
      '75%': {
        top: -75,
      },
      '100%': {
        top: -65,
      },
    },
    '@keyframes dropItLikeItsHotMobile': {
      '0%': {
        top: -550,
      },
      '50%': {
        top: -140,
      },
      '75%': {
        top: -160,
      },
      '100%': {
        top: -150,
      },
    },
    root: {
      margin: 'auto',
      animationName: '$dropItLikeItsHot',
      animation: '$dropItLikeItsHot 0.5s ease-in-out',
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: -45,
      left: 0,
      right: 0,
      color: 'rgba(0,0,0,.6)',
      ['@media (max-width: 768px)']: {
        animationName: '$dropItLikeItsHotMobile',
        top: -150,
      },
    },
    image: {
      height: 'auto !important',
      width: 'auto !important',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    fofText: {
      fontSize: 96,
      marginRight: 20,
      height: 'fit-content',
      lineHeight: '116px',
      ['@media (max-width: 768px)']: {
        fontSize: 60,
        lineHeight: '80px',
      },
    },
    message: {
      position: 'absolute',
      rotate: '10deg',
      display: 'flex',
      alignItems: 'center',
      top: 360,
      width: 550,
      paddingLeft: 20,
      ['@media (max-width: 768px)']: {
        fontSize: 14,
        width: 300,
        top: 310,
        paddingLeft: 0,
      },
    },
    oopsTitle: {
      fontSize: 36,
      lineHeight: '44px',
      ['@media (max-width: 768px)']: {
        fontSize: 20,
        lineHeight: '24px',
      },
    },
    oopsSubtext: {
      fontSize: 17,
      lineHeight: '24px',
      marginTop: 25,
      ['@media (max-width: 768px)']: {
        marginTop: 10,
        fontSize: 14,
        lineHeight: '20px',
      },
    },
  },
  {
    name: 'CiamPortal404Page',
  },
);
