import React from 'react';
import { CiamPortal404Page } from '../../components/pages/CiamPortal404Page';
import { CssBaseline } from '../../components';
import { withLang } from '../../server/withUser';

export interface ICustom404PageProps {
  lang: string;
}

const CiamPortal404: React.FC<ICustom404PageProps> = () => {
  return (
    <CssBaseline variant={'ciam'}>
      <CiamPortal404Page />
    </CssBaseline>
  );
};

export const getServerSideProps = withLang();

export default CiamPortal404;
